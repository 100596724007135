.text-block-center-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .text-block-center-title-wrapper {
    .sub-title {
      margin: 6px;
    }
  }

  .text-block-center-paragraph-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: "proxima-nova", sans-serif;
    font-weight: 300;

    & > p:first-of-type {
      margin-top: 0;
    }

    p {
      text-align: center;
    }
  }
}
