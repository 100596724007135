.problem-block-wrapper {
  .problem-block-text-info{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .sub-title {
      font-size: 22px;
      margin-top: 30px;
      margin-bottom: 0;
    }
  }

  .problem-block-info {
    margin: 50px 0;
    width: 100%;

    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;

    .block {
      flex-grow: 1;
    }
  }

  .problem-block-footer-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .problem-block-footer-info {
      display: flex;
      max-width: 900px;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      p,
      span {
        margin: 0;
        text-align: center;
      }

      .small {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .problem-block-wrapper .problem-block-info {
    width: 730px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 850px) {
  .problem-block-wrapper {
    .problem-block-text-info{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      h2 {
        text-align: center;
      }
    }

    .problem-block-info {
      margin-top: 50px;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;

      .block {
        flex-grow: 1;
      }
    }

    .problem-block-footer-info-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .problem-block-footer-info {
        display: flex;
        max-width: 900px;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .small {
          text-align: center;
        }
      }
    }
  }
}
