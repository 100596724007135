.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;

  h3 {
    margin-bottom: 15px;
  }

  p {
    font-weight: 300;
    margin: 0 !important;
  }

  ul {

    list-style: none;
    li {
      color: var(--text-color-secondary) !important;
      font-size: 15px;
      line-height: 22px;
      position: relative;

      margin: 8px 0;

      &:before {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: #00BDF1;
        box-shadow: 0px 0px 8px 1px #00BDF1;
        margin-right: 22px;
        right: 100%;
        top: 11px;
        transform: translateY(-50%);
        border-radius: 50px;
      }
    }
  }

  .column-title {
    color: var(--text-color);
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 24px 0;
    font-weight: var(--font-weight-info-block);
  }

  Button {
    margin-top: 24px;
  }

  .column-info-images-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;

    img {
      object-fit: contain;
      height: 100%;
      max-height: 50px;
    }
  }
}

@media screen and (max-width: 850px) {
  .column {
    margin: 10px 0;

    p {
      font-size: var(--mobile-body-font-size);
      line-height: 19px;
    }

    span {
      font-size: var(--mobile-body-font-size);
      line-height: 19px;
    }
  }
}
