.member-image-wrapper {
  position: relative;
  height: 250px;
  user-select: none;
}

.side-text {
  color: white;
  position: absolute;
  right: -125px;
  transform: rotate(90deg);
  top: 160px;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: start;
  width: 100%;
  z-index: 2;
}



@media screen and (max-width: 850px) {
  .side-text {
    top: 65px;
    right: -45px;
    font-size: var(--mobile-body-font-size);
  }

  .member-image-wrapper {
    height: 110px;
    width: 100%;

    img {
      position: absolute;
      bottom: 0;
    }
  }
}
