.workway-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  counter-reset: section;

  h3 {
    margin: 0 0 5px 0 !important;
  }

  .text-block-wrapper {
    .image-wrapper {
      right: -100px;
    }

    .text-block-info-wrapper {
      justify-content: flex-start;
      margin-top: 75px;
      width: 70%;

      .companies-wrapper
      .companies {
        top: 20px;
        
        @media only screen and (max-width: 1500px)  {
          padding: 15px 50px;
        }
      }
    }
  }

  .solution-block-wrapper{
    margin-top: 25px !important;

    .left-information-block {
      margin-top: 125px !important;
    }
  }

  .container {
    .column-wrapper:not(:nth-child(1)) {
      margin-top: 50px !important;
    }
  }

  .faq-title {
    width: 100%;
    margin-bottom: 20px;
  }

  .faq-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @media only screen and (max-width: 850px)  {
      flex-direction: column;
      gap: 20px;
    }

    .faq-column {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }

    .border-block {
      cursor: pointer;
      width: 100%;
    }
  }

  .column-paragraph-wrapper {

    .column-info-images-wrapper img {
      width: 100% !important;
      max-height: 100% !important;
    }
    .column-title {
      &:before {
        counter-increment: section;
        content: "0" counter(section) ". ";
        font-family: "Anonymouse Pro Regular", sans-serif;
        font-weight: bold;
        font-size: 18px;
        color: #00BDF1;
      }
    }
  }

  .border-block-info-content {
    span {
      &:before {
        counter-increment: section;
        content: "0" counter(section) ". ";
        font-family: "Anonymouse Pro Regular", sans-serif;
        font-weight: bold;
        font-size: 18px;
        color: #00BDF1;
      }
    }
  }
}

@media screen and (max-width: 850px){
  .workway-wrapper{
    .text-block-wrapper {
      .image-wrapper {
        right: initial;
      }
      
      .text-block-info-wrapper {
        width: 100%;
      }

      .companies-wrapper
      .companies {
        padding: 15px 20px 15px 110px !important;
      }
    }
    .solution-block-wrapper{
      .left-information-block {
        margin: 90px 0 0 0 !important;
      }
    }

    .column-divider {
      display: none !important;
    }
  }

  .highlight-block-wrapper {
    margin-top: 50px !important;
  }
}
