.student-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .quote-wrapper {
    padding: 90px 0;
  }

  .quote-title {
    margin-bottom: 35px;
  }

  .question-title {
    margin-bottom: 35px;
    text-align: center;
  }

  .column-wrapper {
    margin-top: 0 !important;

    .border-block-wrapper {
      height: 100%;
    }
  }
}

@media screen and (min-width: 850px) and (max-width: 1500px) {
  .student-wrapper .image-wrapper img {
    height: 100%  !important;
    width: unset !important;
  }
}

@media screen and (max-width: 850px){
  .student-wrapper{
    .solution-block-wrapper{
      .left-information-block {
        margin: 50px 0 0 0 !important;
      }
    }
  }
}