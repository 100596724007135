.highlight-block-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 560px;
  padding: 150px 0;

  border-radius: 40px;

  background: url("../../../images/full-block-transparent-bg.svg") no-repeat, var(--bg-color-secondary);
  background-size: cover;

  .highlight-block-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 5px 0 30px 0;
      max-width: 600px;
    }

    .sub-title {
      text-align: center;
    }
  }
}

@media screen and (max-width: 850px) {
  .highlight-block-wrapper {
    background: var(--bg-color-secondary) url("../../../images/rysst-small-uitgelicht.svg")  no-repeat -60px top;
    background-size: revert;
    background-position-x: right;
    height: 388px;
    padding: 50px 60px 50px 30px;
    align-items: unset;

    .highlight-block-title {
      .title {
        width: 100%;
        text-align: left !important;
      }

      p {
        width: 100%;
        text-align: left !important;
      }
    }
  }
}
