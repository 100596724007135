.header-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
  }
}
