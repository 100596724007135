.mobile-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

}
.slide {
  display: flex;
  align-items: center;
}

.carousel-slider {
  overflow: unset !important;
}

.control-dots {
  bottom: -60px !important;

  .dot {
    height: 18px !important;
    width: 18px !important;
    background-color: #474747 !important;

    &:hover:not(.selected) {
      opacity: .3 !important;
    }
  }

  .selected {
    background-color: #00BDF1 !important;
    box-shadow: 0px 0px 8px 1px #00BDF1 !important;
    opacity: 1 !important;
  }
}




