* {
  box-sizing: border-box;
}

.header-containter {
  position: relative;
  height: 135px;
  width: 100%;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  margin: 20px 0;
  position: fixed;
  width: 100%;
  z-index: 101;

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-wrapper-left {
      display: flex;
    }

    .logo {

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 145px;
        max-height: 75px;
      }
    }

    .items {
      display: flex;
      align-items: center;
      justify-content: left;

      nav {
        ul {
          list-style-type: none;

          .selected {
            a {
              color: var(--text-color);
              font-weight: bold;
              opacity: 1;

              &:hover {
                color: var(--text-color);
              }
            }
          }

          li {
            a {
              text-decoration: none;
              color: var(--text-color-secondary);

              &:hover {
                color: var(--color-menu-hover);
              }
            }

            display: inline-block;
            cursor: pointer;
            margin-right: 15px;
            margin-left: 15px;
            font-size: 20px;
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: auto;
      margin-bottom: auto;
      color: white;

      .menu-button-wrapper {
        display: none;
      }

      .moon-icon,
      .sun-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 4px;
        height: 45px;
        min-width: 45px;
        cursor: pointer;

        &:hover {
          background-color: var(--color-hover-menu-icons-light);
        }
      }

      .localization-dropdown {
        position: relative;
        user-select: none;
        margin-right: 5px;

        .localization-dropdown-selected {
          display: flex;
          align-items: center;
          color: var(--text-color);
          cursor: pointer;
          padding: 8px;
          border-radius: 4px;
          margin: unset;

          &:hover {
            background-color: var(--color-hover-menu-icons-light);
          }
        }

        .localization-dropdown-list {
          position: absolute;
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: #FFFFFF;
          color: #131313;
          bottom: 0;
          left: 0;
          transform: translateY(100%);
          border-radius: 4px;
          overflow: hidden;
          z-index: 1001;
          box-shadow: 0px 6px 7px 1px rgba(0,0,0,0.47);

          span{
            padding: 4px 8px;
            cursor: pointer;

            &:hover {
              background-color: #00c1f657;
            }
          }

          .localization-dropdown-item-disabled {
            cursor: not-allowed;
            background-color: #EBEBE4;
            color:  #C6C6C6;

            
            &:hover {
              background-color: #EBEBE4;
            }
          }
        }
      }

      button {
        margin-left: 15px;
      }

      flex-direction: row;
    }
  }


  &.sticky {
    top: 0;
    width: 100%;
    z-index: 100;
    margin-top: 0 !important;
    padding: 20px 0;

    background-color: var(--bg-color-sticky);

    .buttons {
      .sun-icon,
      .moon-icon {
        &:hover {
          background-color: var(--color-hover-menu-icons-dark);
        }
      }

      .localization-dropdown {
        .localization-dropdown-selected {
          color: var(--bg-color);

          &:hover {
            background-color: var(--color-hover-menu-icons-dark);
          }
        }
      }
    }

    a {
      color: var(--bg-color) !important;

      &:hover {
        color: var(--color-menu-hover) !important;
      }
    }

    .secondary {
      border: 1px solid var(--bg-color) !important;
      color: var(--text-color-secondary) !important;
    }
  }
}

@media screen and (min-width: 850px) and (max-width: 1500px) {
  header {
    padding: 0 40px;

    &.sticky {
      padding: 20px 40px;
    }
  }

}

@media screen and (max-width: 1280px) {
  .header-containter .contact-form {
    display: none;
  }
  
  .mobile-white {
    background-color: #FFFFFF;
    position: fixed;
    z-index: 1000;
    width: 100%;

    .localization-dropdown-selected {
      color: var(--color-black) !important;
    }

    .sun-icon svg,
    .moon-icon svg {
      color: var(--color-black) !important;
    }

    .transparent-gray {
      color: var(--color-black) !important;
      border: 1px solid var(--color-black) !important;

      &:hover {
        color: var(--color-primary) !important;
        border: 1px solid var(--color-primary) !important;

        .icon-wrapper svg {
          color: var(--color-primary) !important;
        }
      }

      .icon-wrapper svg {
        color: var(--color-black) !important;
      }
    }
  }

  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10px 32px 10px 32px;

    &.sticky {
      padding: 10px 32px 10px 32px;
    }

    .header-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        img {
          max-width: 100px;
        }
      }

      .items {
        order: 1;
        margin-left: 25px;

        nav {
          display: none !important;
        }
      }

      .buttons {
        .menu-button-wrapper {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          width: 100%;
          order: 1;
          margin-left: 15px;

          #collapse-button {
            display: none;
          }
        }

        a {
          display: none;
        }
      }
    }

    .mobile-menu-items-wrapper {
      background-color: #FFFFFF;
      position: fixed;
      width: 100vw;
      height: 100%;
      top: 70px;
      left: 0;
      z-index: 1000;

      .mobile-menu-items {
        nav {
          ul {
            list-style-type: none;
            padding: 0;

            .selected {
              position: relative;

              &:after {
                content: "";
                background-image: url("../../images/underline.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: bottom;
                width: 40%;
                height: 50%;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                bottom: -12px;
              }
            }

            li {
              text-align: center;
              cursor: pointer;
              margin: 35px 0;
              font-size: 20px;

              a {
                text-decoration: none;
                color: var(--text-color-secondary);

                font-size: 34px;
                font-weight: 300;
                letter-spacing: -0.02px;
                line-height: 41px;

                &:hover {
                  color: var(--text-color-secondary);
                }
              }
            }
          }
        }

        .mobile-menu-footer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 78px;

          button {
            margin: 12px 0;
          }

          p {
            color: #000000;
            font-family: "proxima-nova", sans-serif;
            font-size: var(--mobile-body-font-size);
            letter-spacing: -0.01px;
            line-height: 22px;
            text-align: center;
          }

          .copyright {
            opacity: 0.59;
            color: #BBBBBB;
            font-family: "Anonymouse Pro Regular", sans-serif;
            font-size: var(--mobile-body-font-size);
            letter-spacing: -0.31px;
            line-height: 16px;
          }
        }
      }
    }

  }
}


