.horizontal {
  height: 1.5px;
  width: 100%;
  margin: 20px 0;
  margin-top: 50px;
  border-radius: 50%;
}

.gray {
  background-color:#979797;
  opacity: 0.27;
}

.white {
  background-color: var(--text-color);
}

.blue {
  background-color:#00BDF1;
}
