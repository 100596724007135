.about-wrapper {
  .header-image-wrapper {
    margin-top: 25px !important;
  }

  .team-carousel {
    margin-top: 62px !important;
  }

  .student-title {
    width: 100%;
    text-align: left;
    margin-top: 62px;
  }

  .text-block-wrapper .image-wrapper img {
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
  }
}

@media screen and (min-width: 850px) {
  .about-wrapper .image-wrapper img {
    margin-right: unset;
  }
}

@media screen and (max-width: 850px) {
  .about-wrapper {

    .highlight-block-wrapper {
      margin-top: 50px !important;
    }

    .student-title {
      margin-top: 50px;
      text-align: center;
    }

    .team-carousel {
      margin-top: 10px !important;
    }

    .text-block-wrapper {
      flex-direction: column;

      .text-block-info-wrapper {
        margin-top: unset !important;
      }

      .image-wrapper {
        margin-top: 70px !important;
      }
    }
  }
}
