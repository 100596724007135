.vacancy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 650px;

  .vacancy-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;

    .vacancy-content {
      
      .vacancy-subtitle {
        margin: unset;
      }

      .vacancy-title {
        font-size: 23px;
        font-family: 'proxima-nova', sans-serif !important;
      }
    }

    .vacancy-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 200px;
      height: 100%;
      object-fit: contain;
      border-radius: 12px;
      margin-right: 40px;
    }
  }
}

@media screen and (max-width: 1590px) {
  .vacancy {
    width: 500px;
  }
}

@media screen and (max-width: 850px) {
  .vacancy {
    width: 100%;

    .vacancy-content-wrapper {
      margin-right: 10px;

      .vacancy-content {
        .vacancy-title {
          font-size: 16px;
          line-height: 18px;
        }
      }

      img {
        width: 150px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .vacancy {
    .vacancy-content-wrapper {
      flex-wrap: wrap;
    }
  }
}