.team-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1920px !important;

  .team-description-wrapper{
    width: 100%;
    margin: 0 70px;
  }

  &-first {
    margin-top: 40px !important;
  }

  &-reversed {
    flex-direction: row-reverse;

  }

  .team-member-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1500px) {
  .team-wrapper {
    flex-direction: column ;
    justify-content: center;

    &-first {
      margin-top: 20px !important;
    }

    .team-carousel {
      justify-content: center;
    }

    .team-description-wrapper {
      margin: 0 0 20px 0;

      .border-block {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .team-wrapper {
    .team-description-wrapper {
      margin: 0 0 20px 0;

      .border-block-text {
        font-size: var(--mobile-body-font-size) !important;
      }
    }
  }
}
