.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  --mobile-body-font-size: 14px;
  --mobile-subtitle-font-size: 15px;
  --color-primary: #00BDF1;
  --text-color: #FFFFFF;
  --text-color-secondary: #BBBBBB;
  --bg-color: #131313;
  --bg-color-secondary: #171717;
  --bg-color-sticky: #FFFFFF;
  --color-black: #131313;
  --color-black-transparant: #131313A3;
  --color-black-secondary: #171717;
  --color-white: #FFFFFF;
  --color-white-secondary: #BBBBBB;
  --color-black-to-nothing: #171717;
  --svg-filter: none;
  --svg-filter-info-block: none;
  --color-blue-to-white: #00BDF1;
  --color-blue-to-black: #00BDF1;
  --color-border-block-gray: rgba(83, 73, 73, 1);
  --font-family-paragraph: 'proxima-nova', sans-serif;
  --font-weight-paragraph: 300;
  --color-primary-blue-to-white: #00BDF1;
  --color-white-to-primary-blue: #FFFFFF;
  --color-black-to-white: #131313;
  --color-gray-to-blue: #898989;
  --color-menu-hover: #898989;
  --font-weight-info-block: normal;
  --shadow-team-carousel-width: 100px;
  --color-team-carousel: #131313;
  --color-hover-menu-icons-dark: rgba(0, 0, 0, 0.050);
  --color-hover-menu-icons-light: rgba(255, 255, 255, 0.106);

  --color-footer-menu-item: #898989;
  --color-footer-menu-item-hover: #131313;

  body {
    background-image: radial-gradient(circle at top right,
    #1772BD 0vw,
    #3045A1 1vw,
    #0C141A 40vw,
    #131313 60vw);


    &::after {
      background: radial-gradient(circle at left, #1772BD 0, #3045A1 1%, #0C141A 40%, #131313 60%);
    }
  }
}

html[data-theme='light'] {
  --text-color: #131313;
  --text-color-secondary: #171717;
  --bg-color: #FFFFFF;
  --bg-color-secondary: #F5F5F5;
  --bg-color-sticky: #222222;
  --color-black: #131313;
  --color-black-transparant: #131313A3;
  --color-black-secondary: #171717;
  --color-white: #FFFFFF;
  --color-white-secondary: #F5F5F5;
  --color-black-to-nothing: unset;
  --color-blue-to-white: #FFFFFF;
  --color-blue-to-black: #131313;
  --svg-filter: grayscale(1) invert(1);
  --svg-filter-info-block: invert(1) hue-rotate(180deg);
  --color-border-block-gray: #e8e8e8;
  --font-family-paragraph: 'proxima-nova', sans-serif;
  --font-weight-paragraph: 400;
  --color-primary-blue-to-white: #00BDF1;
  --color-white-to-primary-blue: #00BDF1;
  --color-black-to-white: #FFFFFF;
  --color-gray-to-blue: #1772BD;
  --color-menu-hover: #D0D0D0;
  --font-weight-info-block: bold;
  --shadow-team-carousel-width: 50px;
  --color-team-carousel: unset;
  --color-hover-menu-icons-light: rgba(0, 0, 0, 0.050);
  --color-hover-menu-icons-dark: rgba(255, 255, 255, 0.106);

  --color-footer-menu-item: #FFFFFF;
  --color-footer-menu-item-hover: #D0D0D0;

  body {

    background-image: radial-gradient(circle at top right,
            rgba(23, 114, 189, 0.2) 0vw,
            rgba(0, 192, 246, 0.2) 1vw,
            rgba(244, 244, 244, 0.59) 40vw,
            #FFFFFF 60vw);


    &::after {
      background: radial-gradient(circle at left,
              rgba(23, 114, 189, 0.2) 0%,
              rgba(0, 192, 246, 0.2) 1%,
              rgba(244, 244, 244, 0.59) 40%,
              #FFFFFF 60%)
    }
  }
}
  .wrapper {
    display: flex;
    padding: 0 32px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    overflow-x: hidden;

    &-main {
      min-height: calc(100vh);
    }

    p {
      font-family: var(--font-family-paragraph), sans-serif;
      line-height: 22px;
      font-size: 15px;
      color: var(--text-color-secondary);
      font-weight: 300;

      strong {
        color: var(--text-color);
      }
    }

    .seperator {
      border: 1px solid #979797;
      opacity: 27%;
      width: 40%;
      margin: 50px 0;
    }

    .vertical {
      width: 1px;
    }

    .title,
    h1, h2, h3, h4, h5, h6 {
      color: var(--text-color);
      font-weight: bold;
    }

    .title, h1, h2 {
      font-size: 35px;
      line-height: 40px;
    }

    h3 {
      font-size: 18px;
    }

    .sub-title {
      font-size: 18px;
      color: var(--text-color-secondary);
      margin-top: 0;
      font-family: 'proxima-nova', sans-serif;
      font-weight: 400;
    }

    .container:not(:nth-child(1)){
      margin-top: 100px;
    }
  }

  .container {
    width: 100%;
    max-width: 1500px;

    @media screen and (max-width: 1920px) {
      width: 100%;
      max-width: 1500px;
    }

    .small {
      color: var(--text-color);
      font-size: 15px;
      line-height: 22px;
    }

    @media screen and (max-width: 850px) {
      .small {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .bold {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 850px) {
    .wrapper {

      p {
        font-size: var(--mobile-body-font-size);
      }

      .sub-title {
        font-size: var(--mobile-subtitle-font-size) !important;
      }

      .title, h1, h2 {
        font-size: 24px;
        line-height: 30px;
      }

      .seperator {
        width: 50%;
      }

      .container:not(:nth-child(1)){
        margin-top: 50px;
      }
    }
  }





//pages

//other components
@import "./style/dark-background";
@import "./style/cookie-banner";
