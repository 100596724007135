.cookie-container {
  background-color: var(--text-color) !important;
  width: 360px !important;
  left: 20px !important;
  bottom: 20px !important;
  font-family: 'proxima-nova', sans-serif !important;
  font-weight: 300;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 9px rgb(0 0 0 / 15%);
  padding: 10px 15px;

  .cookie-content-wrapper {
    color: var(--bg-color);
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  .cookie-button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .cookie-button-accept,
    .cookie-button-decline {
      font-weight: bold;
    }

    .cookie-button-decline {
      color: var(--bg-color) !important;
      background-color: unset !important;
      padding: 10px 0 !important;
    }

    .cookie-button-accept {
      background-color: #00C0F6 !important;
      color: var(--text-color) !important;
      border-radius: 5px !important;
      border-radius: 22px !important;
      padding: 10px 15px !important;
    }
  }
}
