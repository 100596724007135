.column-wrapper {
  display: flex;
  flex-direction: column;

  .column-paragraph-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 128px;
  }

  .column-button-wrapper {
    margin: 65px 0 0 0;
  }
}

@media screen and (max-width: 850px) {
  .column-wrapper{
    .column-paragraph-wrapper {
      flex-wrap: wrap;
      gap: 20px;

      .column {
        margin: 10px 0;

        p {
          font-size: var(--mobile-body-font-size);
          line-height: 19px;
        }

        span {
          font-size: var(--mobile-body-font-size);
          line-height: 19px;
        }
      }
    }
  }
}
