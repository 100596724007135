button {
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  font-family: "proxima-nova", sans-serif;
  border-radius: 22px;
  height: 43px;


  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 26px;
    font-size: 18px;
  }

  .button-text {
    margin: 0;
  }
}

.primary{
  background-color: #00C0F6;
  color: var(--color-white);
  border: none;

  &:hover{
    background-color: #1772BD;
  }

  .icon-wrapper {
    color: var(--color-black);
  }
}

.secondary{
  background-color: var(--color-white-to-primary-blue);
  color: var(--color-black-to-white);
  border: none;

  &:hover {
    background-color: var(--color-gray-to-blue);

    .icon-wrapper {
      color: var(--color-black);
    }
  }

  .icon-wrapper {
    color: var(--color-blue-to-black);
  }
}

.transparent {
  border:1px solid var(--text-color);
  color: var(--text-color);
  background-color: transparent;

  .icon-wrapper .icon {
    color: #00BDF1;
  }

  &:hover {
    border: 1px solid #00BDF1;
    color: #00BDF1;
  }
}

.transparent-gray{
  border:1px solid var(--bg-color);
  color: var(--bg-color);
  background-color: transparent;

  .icon-wrapper .icon {
    color: var(--bg-color);
  }

  &:hover {
    border: 1px solid #00BDF1;
    color: #00BDF1;
  }
}

.header {
  font-size: 16px;
  border-radius: 24px;
  color: var(--text-color);

  .icon-wrapper {
    margin-left: 8px;
  }

  &.primary {
    color: var(--color-white);

    .icon-wrapper {
      color: var(--color-white);
    }
  }

  &.transparent {
    .icon-wrapper .icon {
      color: var(--text-color);
    }

    &:hover {
      .icon-wrapper .icon {
        color: var(--color-primary);
      }
    }
  }

  &.transparent-gray {
    color: var(--bg-color);

    &:hover {
      color: var(--color-primary);

      .icon-wrapper .icon {
        color: var(--color-primary);
      }
    }
  }
}

@media screen and (max-width: 850px) {
  button {
    height: 40px;
  
    .button-text {
      font-size: var(--mobile-body-font-size);
    }
  }
}
