.dark-background {
  position: relative;
  background: var(--bg-color-secondary);

  &:before, &:after {
    content: "";
    display: block;
    background: var(--bg-color-secondary);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    left: -100%;
  }

  &:after {
    left: 100%;
  }
}

@media screen and(min-width: 1921px) {
  .dark-background {
    &:before, &:after {
      content: "";
      width: 40px;
      height: 100%;
    }

    &::before {
      left: 100%;
      border-radius: 0 20px 20px 0;
    }

    &::after {
      left: -40px;
      border-radius: 20px 0 0 20px;
    }
  }
}
