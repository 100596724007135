.contact-form-no-cookies {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  p {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #131313;
    margin: unset;
  }
}