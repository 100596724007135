.vacancy-wrapper {  
  width: 100%;
  padding: unset;

  .vacancy-description-container {
    display: flex;
    gap: 50px;
    margin-top: 50px;
    max-width: 1000px;
    width: 100%;

    .vacancy-process-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: var(--bg-color-secondary);
      padding: 30px;
      width: 35%;
      border-radius: 12px;
      height: 750px;
      position: relative;
      min-width: 308px;

      .vacancy-form {
        position: absolute;
        top: 0;
        left: 0;
        height: 750px;
        width: 100%;
        padding: 30px 20px;
        background-color: var(--color-white);
        box-shadow: 0px 2px 7px 1px rgba(0,0,0,0.25);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 1;
        border-radius: 12px;

        div {
          margin: auto 0;
        }

        .vacancy-form-button {
          position: absolute;
          width: 100%;
          bottom: 30px;
          display: flex;
          justify-content: center;
        }
        
        .vacancy-form-header {
          display: flex;
          justify-content: space-between;
          width: 100%;

          svg {
            cursor: pointer;
          }

          .vacancy-process-title {
            color: var(--color-black);
            width: 100%;
            text-align: left;
            margin-bottom: 10px;
          }
        }
      }

      .vacancy-process {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;

        a {
          color: #00C0F6;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .vacancy-process-title {
        font-size: 23px;
        font-weight: bold;
        color: var(--text-color);
      }

      p {
        margin: 0;
      }
    }

    .vacancy-description {
      width: 75%;
      color: var(--text-color-secondary);

      p {
        margin: 0;
        color: var(--text-color-secondary);

        strong {
          color: var(--text-color);
        }
      }

      li {
        font-family: "proxima-nova", sans-serif;
        font-weight: 300;
      }
    }
  }

  .vacancy-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .vacancy-title {
      text-align: center;
    }

    .sub-title {
      margin-bottom: 50px;
    }
    
    .border-block {
      max-width: 1000px !important;

      .border-block-wrapper {
        max-width: 1000px !important;
      }
    }

    .vacancy-header-wrapper {
      display: flex;
      gap: 40px;
      width: 100%;

      .vacancy-header-info-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 30px;
        width: 100%;

        .vacancy-header-info {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .vacancy-info-item {
            color: var(--text-color);
            display: flex;
            align-items: center;
            gap: 10px;

            .vacancy-info-item-title {
              font-weight: bold;
              width: 170px;
              color: var(--text-color);
            }

            p {
              white-space: nowrap;
              margin: 0;
            }
          }  
        }
      }

      img {
        width: 200px;
        height: 100%;
        object-fit: contain;
        border-radius: 12px;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .vacancy-wrapper .vacancy-title-container .vacancy-header-wrapper img {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .vacancy-wrapper {
    .sub-title {
      margin-bottom: 25px !important;
    }

    .vacancy-description-container {
      flex-direction: column-reverse;

      .vacancy-process-wrapper {
        width: 100%;
        height: unset;
        position: initial;
        min-width: unset;

        .vacancy-form {
          position: fixed;
          top: 0;
          z-index: 10000;
          border-radius: unset;
          height: 100%;

          .vacancy-form-header {

            svg {
              position: absolute;
              right: 30px;
              top: 30px;
            }

            .vacancy-process-title {
              text-align: center;
              font-size: 23px;
            }
          }
        }

        .vacancy-process-title {
          font-size: 20px;
        }
      }

      .vacancy-description {
        width: 100%;
        font-size: var(--mobile-body-font-size);
      }
    }

    .vacancy-title-container 
    .vacancy-header-wrapper 
    .vacancy-header-info-wrapper
    .vacancy-header-info
    .vacancy-info-item {
      .vacancy-info-item-title {
        width: 150px;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .vacancy-wrapper .vacancy-title-container .vacancy-header-wrapper {
    justify-content: center;

    .vacancy-header-info-wrapper {
      flex-direction: column;
      gap: 5px;
      width: unset;
    }

    .vacancy-header-info {
      width: 100%;
    }
  }
}


@media screen and (max-width: 420px) {
  .vacancy-wrapper .vacancy-info-item-title {
    width: unset !important;
  }
}