.home {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 850px)  {
  .home {
    .seperator {
      margin: 90px 0 0 0 !important;
    }

    .problem-block-wrapper {
      margin-top: 150px !important;
    }
    
    .problem-block-text-info h2 {
      max-width: 700px !important;
      text-align: center;
    }
  
    .review-block-wrapper {
      margin-top: 120px !important;
    }
  
    .solution-block-wrapper{
      margin-top: 90px !important;
  
      .right-information-block {
        margin-top: -40px !important;
        @media only screen and (max-width: 850px)  {
          margin-top: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 1921px)  {
  .home {
    .problem-block-wrapper {
      margin-top: 0 !important;
    }
  }
}

@media only screen and (max-width: 850px)  {
  .seperator {
    margin: 50px 0 !important;
  }

  .problem-block-wrapper {
    margin-top: 200px !important;

    .sub-title {
      text-align: center !important;
    }
  }

  .solution-block-wrapper,
  .highlight-block-wrapper{
    margin-top: 0 !important;
  }
}