.big-title-block-item-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  counter-reset: section;
  
  .left-information-block {
    margin-right: 40px;
    width: 50%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      margin: 0 0 20px 0;
      font-size: 60px;
      line-height: 65px;
      font-weight: 600;

      span {
        font-family: "Anonymouse Pro Regular", sans-serif;
        font-weight: bold;
        color: #00BDF1;
      }
    }

    .left-information-block-button {
      margin: 10px 0 35px 0;
    }

    .left-information-block-text {
      p {
        font-weight: 500;
        font-size: 20px;
        margin: 0;
      }

      ul {

        list-style: none;
        li {
          color: var(--text-color-secondary) !important;
          font-size: 15px;
          line-height: 22px;
          position: relative;

          margin: 8px 0;

          &:before {
            content: '';
            position: absolute;
            width: 7px;
            height: 7px;
            background-color: #00BDF1;
            box-shadow: 0px 0px 8px 1px #00BDF1;
            margin-right: 22px;
            right: 100%;
            top: 11px;
            transform: translateY(-50%);
            border-radius: 50px;
          }
        }
      }

      .left-information-block-small {
        margin-top: 20px;

        p {
          font-size: 15px;
          font-weight: 300;
        }
      }
    }

  }

  .right-information-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 500px;
      max-height: 550px;
    }
  }
}

.reverse {
  flex-direction: row-reverse;

  .left-information-block{
    margin-top: 40px;
  }

  .right-information-block {
    justify-content: flex-start !important;
  }

}

@media screen and (max-width: 850px) {
  .big-title-block-item-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    .left-information-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-right: 0;
      padding: 0;

      .left-information-block-title{
        .title {
          font-size: 20px;
          line-height: 25px;
        }
      }

      .left-information-block-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        order: 1;
      }

      .right-information-block{
        margin: 50px 0 20px 0;
      }

      .left-information-block-text {
        p {
          font-size: 16px;
        }
      }

    }

    .right-information-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      width: 100%;

      img {
        width: 100%;
        margin: unset;
        max-height: 200px;
      }
    }
  }
}
