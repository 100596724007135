.vacancies-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .vacancy-filter-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: var(--text-color);

    .vacancy-filter {
      display: flex;
      align-items: center;
      line-height: 16px;
      margin-top: 20px;
      cursor: pointer;

      .vacancy-filter-checkbox {
        border: #00C0F6 1px solid;
        height: 16px;
        width: 16px;
        margin-right: 0.5rem;
        background-color: var(--bg-color);
        transition: all 0.5s ease-in-out;
        border-radius: 4px;

        &.selected {
          background-color: #00C0F6;
        }
      }
    }

    input {
      display: none;
    }
  }

  .vacancies-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin: 25px auto 0 auto !important;

    &:after {
      content: '';
      flex: auto;
    }

    .vacancy-disabled {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        right: 100%;
        background-color: rgb(50 50 50 / 68%);
        z-index: 2;
        border-radius: 12px;
      }
    }

    .border-block {
      display: flex;
      justify-content: center;
      width: fit-content;
    }
  }
}

@media screen and (min-width: 1300px) and (max-width: 1590px) {
  .vacancies-wrapper .vacancies-container {
    width: 1200px;
  }
}

@media screen and (max-width: 1300px) {
  .vacancies-wrapper .vacancies-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .vacancies-wrapper {

    .vacancy-disabled {
      width: 100%;
      max-width: 580px
    }

    .highlight-block-wrapper {
      margin-top: 50px !important;
    }

   .vacancies-container {
    .border-block {
      width: 100% !important;

      .border-block-wrapper {
        width: 100%;
        max-width: 600px;
      }
    }
   }
  }
}

@media screen and (max-width: 410px) {
  .vacancies-wrapper {
    .border-block-wrapper {
      .icon-wrapper {
        margin-left: 8px !important;
      }

      img {
        margin-right: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .vacancies-wrapper {
    .border-block-wrapper {
      padding: 20px;

      .vacancy {
        flex-direction: column;
        gap: 20px;

        .vacancy-content-wrapper {
          flex-wrap: nowrap;
          flex-direction: column;
          gap: 10px;
          margin: unset;
          text-align: center !important;

          img {
            width: 100%;
            margin: unset !important;
          }
        }
      }
    }
  }
}