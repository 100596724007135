.wrapper-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  width: 100%;

  img {
    border-radius: 24px;
    margin-bottom: 20px;
  }

  .sub-title {
    a {
      color: var(--color-primary);
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 850px) {
  .wrapper-404 img {
    width: 100%;
    object-fit: contain;
  }
}