.text-block-wrapper {
  display: flex;
  justify-content: space-between;
  font-family: "proxima-nova", sans-serif;
  height: 100%;

  .text-block-info-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    justify-content: center;
    width: 80%;

    .info-paragraph-wrapper {
      color: var(--text-color);

      .text-block-info-paragraph-title {
        margin-bottom: 0;
        font-weight: bold;
      }

      p {
        font-family: "proxima-nova", sans-serif;
        font-weight: 300;
      }

      & > p:first-of-type {
        margin-top: 0;
      }
    }

    .info-button-wrapper {
      margin: 56px 0 0 0;
    }
  }

  .image-wrapper {
    display: flex;
    position: relative;
    justify-content: flex-end;
    width: 100%;
    height: 500px;
    margin-left: 24px;

    img {
      margin-right: calc((100vw - 1500px + 60px) / 2 * -1);
      object-fit: contain;
      height: 100%;
      object-position: right;
      border-radius: 40px 0 0 40px;
    }
    
  }
}

@media screen and (min-width: 784px) and (max-width: 1500px) {
  .text-block-wrapper {
    position: relative;

    .image-wrapper {
      height: 400px;

      img {
        margin-right: -60px;
      }
    }
  }
}

@media screen and (min-width: 1921px) {
  .text-block-wrapper {
    position: relative;

    .text-block-info-wrapper {
      width: 100%;
    }

    .image-wrapper {
      img {
        margin-right: 0;
        border-radius: 40px;
      }
    }
  }
}

@media screen and (max-width: 784px) {
  .companies {
    top: 20px !important;
  }

  .text-block-wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    padding-top: unset;

    .text-block-info-wrapper {
      margin: 30px 0;
      width: 100%;

      .info {
        .info-button-wrapper {
          margin: 24px 0;
        }
      }

      p {
        font-size: 14px;
        font-family: "proxima-nova", sans-serif;
        font-weight: 300;
      }

      .span {
        font-size: 23px;
        color: var(--text-color);
        line-height: 29px;
        margin: 0;
        font-weight: bold;
      }

      .companies-wrapper {
        margin-bottom: 84px;
        .companies {
          position: absolute;
          top: 0;
          left: -148px;
          max-width: 465px;
          padding: 15px 20px 15px 110px;

          .logos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            img {
              max-width: 66px;
            }
          }

          p {
            color: var(--text-color-secondary);
            font-size: 9px;
          }
        ;
          margin-top: 0;

          &::before {
            content: '';
            white-space: pre;
            width: 100%;
            height: 100%;
            background: linear-gradient(149.56deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.02) 100%);
            position: initial;
          }

        }
      }
    }

    .image-wrapper {
      margin-left: unset;
      height: 100%;

      img {
        position: initial;
        top: unset;
        object-fit: contain;
        object-position: center;
        transform: unset;
        border-radius: 20px;
        height: unset;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
