.work-way-block-wrapper {
  background-color: var(--bg-color-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  padding: 80px 0;

  .work-way-block-header {
    display: flex;
    max-width: 1000px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      font-size: 35px;
      font-family: "proxima-nova", sans-serif;
      font-weight: bold;
      text-align: center;
    }

    p {
      text-align: center;
      color: var(--text-color-secondary);
      font-size: 17px;
    }
  }

  .work-way-block-content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .work-way-block-content-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;

      .seperator {
        margin-left: auto;
      }
    }

    .block {
      background-color: transparent !important;
    }

    .image-wrapper {
      height: 100px !important;
    }
  }

  .work-way-block-footer {
    margin-top:25px;
  }
}

@media screen and (max-width: 850px) {
  .work-way-block-wrapper {
    text-align: center;
    padding: 50px 0;
    .work-way-block-header {
      span {
        font-size: 20px;
        line-height: 25px;
        font-family: "proxima-nova", sans-serif;
      }

      p {
        text-align: center;
      }
    }

    .work-way-block-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 30px 0;
    }

    .work-way-block-footer {
      margin-top:25px;
    }
  }
}
