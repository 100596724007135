.whitepaper-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10% 0 10%;
  margin-top: 10%;
  
  @media  screen and (max-width: 850px) {
    flex-direction: column;
    padding: 0 !important;

    .image-block {
      padding: 0 !important;
      padding-bottom: 50px !important;
      margin-top: 0 !important;
      img {
        transform: rotate(0deg) !important;
        width: auto;
        max-width: 800px;
        height: 300px !important;
      }
    }

    .text-block{
      max-width: 90% !important;
      h1 {
        font-size: 21px !important;
        line-height: 20px;
      }
    }

    width: 100% !important;
  }

  .image-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding-right: 80px;

    img {
      width: auto;
      max-width: 800px;
      height: 400px;
      transform: rotate(-10deg);
    }
  }

  .text-block {
    max-width: 50%;
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

      li {
        text-decoration: none;

        a {
          text-decoration: none;
          color: var(--color-white-to-primary-blue);
        }
      }
    }
  }
}