.contact-wrapper {
  flex-direction: row !important;
  justify-content: center !important;
  align-items: flex-start !important;
  width: 100%;
  max-width: 1500px;
  gap: 15%;
  overflow: visible !important;
  min-height: 750px;
  padding: 0;

  .border-block {
    margin-top: 25px !important;
  }

  .contact-location-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    a {
      color: var(--color-primary);
    }

    p {
      margin: 0;
    }

    .sub-title {
      margin-bottom: 24px;
    }

    .contact-locations {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .contact-location {
        display: flex;
        max-width: 650px;

        .border-block-wrapper {
          flex-direction: row;
          gap: 50px;
        }

        .contact-details-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .city {
            color: var(--color-primary) !important;
            font-weight: normal;
            font-size: 22px;
            margin: 0;
          }

          .contact-details {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;
            font-family: 'proxima-nova', sans-serif !important;
            min-width: 250px;
      
            .address {
              color: var(--text-color);
            }

            a {
              color: var(--text-color);
            }

            p {
              display: flex;
              align-items: center;
              margin: 0;
              gap: 5px;
              font-family: 'proxima-nova', sans-serif !important;

              svg {
                height: 18px;
                width: 18px;
              }
            }
          }
        }

        img {
          width: 250px;
          height: 100%;
          object-fit: contain;
          border-radius: 12px;
        }
      }
    }
  }
  
  .contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    box-shadow: 0px 2px 7px 1px rgba(0,0,0,0.25);
    padding: 16px 70px;
    border-radius: 20px;
    width: 100%;
    align-self: stretch;
    max-width: 550px;
  }

  div {
    width: 100%;
  }

  div:first-child {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1300px) {
  .contact-wrapper {
    gap: 40px;
  }
}

@media screen and (max-width: 1100px) {
  .contact-wrapper .contact-location-wrapper .contact-location img {
    width: 100% !important;
  }

  .highlight-block-wrapper {
    max-width: 650px !important;
  }

  .contact-wrapper {
    flex-direction: column !important;
    padding: unset !important;

    .contact-location-wrapper {
      gap: unset;
      margin: 0 auto;
      text-align: center;

      .contact-locations {
        align-items: center;
      }

      .contact-location .border-block .border-block-wrapper {
        flex-direction: column !important;
        gap: 10px;
        text-align: start;
      }
    }

    .contact-form-container {
      margin: 50px auto 0 auto;
      max-width: 650px;
    }
  }
}

@media screen and (max-width: 850px) {
  .contact-wrapper .contact-form-container {
    padding: 24px;
    margin-top: 50px;
  }
}
