.teams-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .column-background-wrapper {
    padding: 80px 0 60px 0;
    margin-top: 100px;
  }

  .column-wrapper {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 1920px) {
  .teams-wrapper {
    .column-background-wrapper {
      margin-top: -50px;
    }
    .text-block-wrapper .image-wrapper img {
      top: 60%;
      z-index: 1;
    }
  }
}

@media screen and (max-width: 1500px) {
  .teams-wrapper {
    .title:last-child {
      text-align: center;
    }

    .team-divider {
      margin-top: 0 !important;
    }

    .highlight-block-wrapper {
      margin-top: 50px !important;
    }
  }
}
