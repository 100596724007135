.reverse {
  flex-direction: row-reverse;
}

.solution-block-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  
  .left-information-block {
    width: 30%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      margin: unset;
    }

    .left-information-block-button {
      margin: 10px 0 35px 0;
    }

    .left-information-block-text {
      p {
        margin: 0;
        font-weight: 300;
      }
    }

  }

  .right-information-block {
    display: flex;
    align-items: center;
    width: 60%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 850px) {
  .solution-block-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .left-information-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .left-information-block-title{
        h2 {
          font-size: 20px;
          line-height: 25px;
        }

        p {
          font-size: var(--mobile-body-font-size);
          line-height: 18px;
        }
      }

      .left-information-block-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        order: 1;
      }

      .right-information-block{
        margin: 50px 0 20px 0;
      }

      .left-information-block-text {
        p {
          font-size: var(--mobile-body-font-size) !important;
        }
      }

    }

    .right-information-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      width: 100%;

      img {
        width: 100%;
        margin: unset;
      }
    }
  }
}
