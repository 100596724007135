@import "../../style.scss";

.border-block-faq {
  padding: unset !important;
}

.border-block-faq-wrapper {
  color: #fff;
  padding: 30px 50px;
  user-select: none;

  .border-block-question-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    p {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
      color: #00BDF1;
    }
  }

  .border-block-answer-wrapper {
    max-height: 1000px;
    overflow: hidden;

    p {
      margin: 0;
      color: #999999;
    }
  }

  &-closed {
    background: var(--bg-color-secondary);
    border-radius: 13px; 

    &:before,
    &:after {
      display: none;
    }

    .border-block-question-wrapper {
      margin-bottom: 0;

      p {
        color: var(--text-color);
      }
    }

    .border-block-answer-wrapper {
      max-height: 0;

      p {
        color: var(--text-color);
      }
    }

  }
}

@media screen and (max-width: 850px) {
  .border-block-faq-wrapper {
    padding: 15px 25px;

    .border-block-question-wrapper {
      svg {
        height: 16px;
        width: 16px;
      }

      p {
        font-size: var(--mobile-body-font-size) !important;
      }
    }
  }
}
