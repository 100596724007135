@import './strapi-style/strapi.scss';

.blog-post-wrapper {
  width: 100%;
  line-height: 22px;
  margin-top: 25px;

  h1, h2, h3, h4, p {
    margin: 0;
  }

  a {
    color: #00C0F6;
    &:hover {
      text-decoration: underline;
    }
  }

  pre {
    background: var(--bg-color-secondary);
    padding: 20px;
    border-radius: 12px;

    code {
      color: var(--text-color);
      padding: unset;
    }
  }

  code {
    background: var(--bg-color-secondary);
    color: rgb(238, 155, 155);
    padding: 4px;
    border-radius: 4px;
  }

  .blog-post-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .blog-post-banner-image {
      max-width: 80%;
      border-radius: 12px;
      height: 350px;
      margin-top: 25px;
      object-fit: contain;
    }
  }

  .blog-post {
    color: white;
    max-width: 1000px;
    margin: 25px auto;

    h3 {
      color: var(--text-color);
    }
  }

  .blog-post-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 850px) {
  h2 {
    line-height: 30px;
  }

  pre {
    code {
      white-space: pre-wrap;
      font-size: 0.7rem;
    }
  }

  .blog-post-banner-image {
    width: 60% !important;
    height: unset !important;
  }
}

@media screen and (max-width: 425px) {

  pre {
    code {
      font-size: 0.55rem;
    }
  }

  h2 {
    font-size: 1.5rem !important;
  }
  .blog-post-wrapper {
    line-height: 15px !important;
  }
}
