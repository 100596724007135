.call-to-action-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 200px;
  padding-top: 40px;
  position: relative;

  .call-to-action-info-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(20% + 200px);

    .companies-wrapper .companies {
      top: 50px;
    }

    .info {
      position: relative;

      .sub-title {
        margin-bottom: 50px;
      }

      .row {
        display: flex;
        flex-direction: row
      }

      .title {
        font-size: 53px;
        color: var(--text-color);
        line-height: 67px;
        margin: 0;
        font-weight: bold;
      }

      .carousel-wrapper {
        .title {
          display: inline;
        }

        display: flex;
        width: 100%;

        .carousel-text-wrapper {
          width: 100%;
          position: relative;
        }

        .underline {
          position: absolute;
          margin-left: 1rem;
          bottom: -15px;

        }

        $transition-speed: 1s;

        .carousel-text {
          display: inline-block;
          min-width: 280px;
          position: absolute;
          margin-left: 1rem;
          transform: translateY(-3rem);
          opacity: 0;


          &[data-sliding="true"] {
            transform: none;
            opacity: 1;
            transition: $transition-speed;
          }
        }

        .carousel-pre-text {
          display: inline-block;
          min-width: 280px;
          position: absolute;
          margin-left: 1rem;
          transform: translateY(-6rem);
          opacity: 0;

          &[data-sliding="true"] {
            transform: translateY(-3rem);
            opacity: 0;
            transition: transform $transition-speed, opacity $transition-speed;
          }
        }

        .carousel-post-text {
          display: inline-block;
          position: absolute;
          margin-left: 1rem;
          transform: none;
          opacity: 1;

          &[data-sliding="true"] {
            font-size: unset;
            transform: translateY(3rem);
            opacity: 0;
            transition: transform $transition-speed, opacity 0.25s;
          }
        }
      }
    }
  }

  .image-wrapper {
    display: flex;
    position: absolute;
    right: calc((100vw - 1500px + 20px) / 2 * -1);
    width: calc(80% - 200px);
    margin-left: 40px;

    .image {
      width: 70%;
      height: 100%;
      object-fit: contain;
      margin-left: auto;
      border-radius: 40px 0 0 40px;
    }
  }
}

@media screen and (min-width: 1921px){
  .call-to-action-wrapper {
    .image-wrapper {
      position: initial;
      .image {
        border-radius: 40px;
      }
    }
  }
}

@media screen and (max-width: 1500px) and (min-width: 850px) {
  .call-to-action-wrapper {
    position: relative;

    .image-wrapper {
      right: -50px;
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 850px) {
  .call-to-action-wrapper {
    .call-to-action-info-wrapper {
      width: calc(20% + 300px);

      .info {
        .title {
          font-size: 35px;
          line-height: 40px;
        }
      }
      
      .carousel-wrapper .carousel-text-wrapper .underline {
        bottom: -7.5px;
        width: 200px;
        height: 10px;
        margin-left: 0.5rem;
      }
    }

    .image-wrapper {
      width: calc(80% - 250px);
    }
  }
}

@media screen and (max-width: 850px) {
  .call-to-action-wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    padding-top: unset;

    .call-to-action-info-wrapper {
      width: 100%;
      margin-top: 40px;

      .info {
        .row {
          display: flex;
          flex-direction: row;
        }

        .title {
          font-size: 23px;
          color: var(--text-color);
          line-height: 35px;
          margin: 0;
          font-weight: bold;
        }

        .carousel-wrapper {
          width: calc(100% - 20px);
          .underline {
            bottom: -7.5px;
            width: 150px;
            height: 10px;
            margin-left: 0.5rem;
          }

          .carousel-text {
            min-width: 125px;
            transform: translateY(-1.5rem);
            margin-left: 0.5rem;
          }

          .carousel-pre-text {
            display: inline-block;
            min-width: 125px;
            margin-left: 0.5rem;
            transform: translateY(-3rem);

            &[data-sliding="true"] {
              transform: translateY(-1.5rem);
            }
          }

          .carousel-post-text {
            margin-left: 0.5rem;

            &[data-sliding="true"] {
              transform: translateY(1.5rem);
            }
          }
        }
      }

      .companies-wrapper {

        .companies {
          position: absolute;
          top: 0;
          left: -120px;
          max-width: 465px;
          padding: 15px 20px 15px 110px;

          .logos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            img {
              max-width: 66px;
            }
          }

          p {
            font-size: 9px;
          }
        ;
          margin-top: 2em;

          &::before {
            content: '';
            white-space: pre;
            width: 100%;
            height: 100%;
            background: linear-gradient(149.56deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.02) 100%);
            position: initial;
          }

        }
      }
    }

    .image-wrapper {
      position: initial;
      width: 100%;
      margin-left: unset;

      .image {
        position: initial;
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}
