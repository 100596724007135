.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--bg-color-sticky);
  color: var(--bg-color);
  width: 100%;
  padding: 50px 40px;
  margin-top: 100px;

  &:before{
    content: "";
    background-color: var(--color-white);
    display: block;
  }

  .footer-menu-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .footer-end-credits {
      width: 100%;

      .footer-end-credits-content {
        p {
          font-family: "proxima-nova", sans-serif;
          line-height: 27px;
          font-size: 17px;
          color: var(--bg-color);
        }
      }

      img {
        max-width: 85px;
        max-height: 45px;
      }

      p {
        max-width: 300px;
      }
    }

    .footer-menu-items-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .footer-menu-generic {
        width: 100%;

        p {
          color: var(--bg-color);
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.01px;
          line-height: 20px;
          font-family: "proxima-nova", sans-serif;
        }

        .footer-menu-generic-items {
          ul {
            list-style-type: none;
            padding: 0;

            li {
              cursor: pointer;
              line-height: 30px;

              a {
                text-decoration: none;
                color: var(--color-footer-menu-item);
                &:hover {
                  color: var(--color-footer-menu-item-hover);
                }
              }
            }
          }
        }
      }

      .footer-menu-work-at {
        width: 100%;

        p {
          color: var(--bg-color);
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.01px;
          line-height: 20px;
          font-family: "proxima-nova", sans-serif;
        }

        .footer-menu-work-at-items {
          ul {
            list-style-type: none;
            padding: 0;

            li {
              cursor: pointer;
              line-height: 30px;

              a {
                text-decoration: none;
                color: var(--color-footer-menu-item);
                &:hover {
                  color: var(--color-footer-menu-item-hover);
                }
              }
            }
          }
        }
      }
    }

    .footer-menu-contact {
      width: 100%;

      .footer-menu-socials {
        margin-top: 20px;
        display: flex;

        a{
          display: flex;
          align-items: center;
        }

        svg {
          color: var(--color-primary);
          width: 20px;
          height: 20px;
          margin-right: 10px;

          &:hover{
            color: #131313;
          }
        }
      }

      .footer-menu-contact-title {
        font-family: "proxima-nova", sans-serif;
        font-weight: bold;
      }

      p {
        color: var(--bg-color);
        font-size: 15px;
        letter-spacing: -0.01px;
        line-height: 20px;
      }

      .title {
        color: var(--color-primary-blue-to-white);
        font-weight: 800;
        margin: 0;
      }

      .footer-menu-contact-adresses {
        display: flex;
        justify-content: space-between;

        .grey-text {
          color: var(--bg-color);
          margin: 0;
        }
      }

      .footer-menu-contact-info {
        margin: 10px 0;

        .grey-text {
          color: var(--bg-color);
          margin: 0;
        }
      }
    }

  }
}

.footer-copyright-wrapper {
  background-color: var(--bg-color-sticky);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .copyright {
    color: var(--color-footer-menu-item);
    font-size: 12px;
  }
}

@media screen and (max-width: 850px) {
  .footer-wrapper {
    padding: 50px 32px;
    margin: 100px 0 0 0;

    .footer-end-credits .footer-end-credits-content p {
      line-height: 22px;
      font-weight: bold;
    }

    .footer-menu-wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;

      .footer-menu-contact-adresses {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
      }


    }

  }
}
