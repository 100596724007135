.blog-page {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "proxima-nova", sans-serif;

  .page-title {
    color: var(--text-color);
  }

  .blog-tags-wrapper {
    display: flex;
    color: var(--text-color);
    font-size: 1.3rem;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    input[type=checkbox] {
      display: none;
    }

    input:checked + .tag-checkbox{
      background-color: #00C0F6;
    }

    label {
      cursor: pointer;
      color: var(--text-color)
    }

    .tag-checkbox {
      display: inline-block;
      border: #00C0F6 1px solid;
      height: 16px;
      width: 16px;
      margin-right: 0.5rem;
      cursor: pointer;
      background-color: var(--bg-color);
      transition: all 0.5s ease-in-out;
      border-radius: 4px;
    }
  }

  .blog-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    max-width: 1200px;

    .blog-post-teaser, .blog-post-teaser-reverse {
      display: flex;
      gap: 20px;

      .blog-post-teaser-thumbnail {
        width: 190px;
        height: 190px;
        border-radius: 12px;

        &-wide {
          width: 360px;
          height: 190px;
          border-radius: 12px;
        }
      }
      .border-block {
        width: 100%;
        .border-block-wrapper {
          height: 100%;
          align-items: center;
          justify-content: center;
          max-width: unset;
        }
      }


      .blog-post-teaser-tag, .blog-post-teaser-title {
        z-index: 2;
        text-align: center;
        width: 100%;
      }

      .blog-post-teaser-tag {
        color: #00BDF1;
      }
    }

    .blog-post-teaser-reverse {
      flex-direction: row-reverse;

      .border-block-wrapper {
        align-items: end;

        &::before, &::after {
          transform: scaleX(-1);
        }
      }
    }
  }
}

@media screen and (min-width: 3000px) {
  .blog-page {
    .blog-list {
      .container {
        max-width: 100% !important;
      }
    }

    .blog-tags-wrapper {
      label {
        font-size: 2.1rem !important;
      }
      .tag-checkbox {
        width: 25px !important;
        height: 25px !important;
        border-radius: 3px !important;
      }
    }

    .border-block-wrapper {
      span {
        font-size: 1.8rem !important;
      }
      h2 {
        font-size: 2.7rem !important;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .blog-page {
    .blog-post-teaser-thumbnail {
      &-wide {
        width: 190px !important;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .blog-page {
    .blog-post-teaser-thumbnail {
      height: 120px !important;
      width: 120px !important;

      &-wide {
        height: 120px !important;
        width: 120px !important;
      }
    }
    .border-block {
      width: 100%;
      .border-block-wrapper {
        height: 120px !important;

        span {
          font-size: 1rem !important;
        }
        h2 {
          font-size: 1.5rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .blog-page {
    .blog-post-teaser-thumbnail {
      display: none;

      &-wide {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .blog-page {
    .border-block-wrapper {
      span {
        font-size: 0.8rem !important;
      }
      h2 {
        font-size: 1rem !important;
      }
    }
    .blog-tags-wrapper {
      label {
        font-size: 0.8rem !important;
      }
      .tag-checkbox {
        width: 9px !important;
        height: 9px !important;
        border-radius: 3px !important;
      }
    }
  }
}
