body{
  margin:0;
  font-family: "proxima-nova", sans-serif;

  overflow-x: hidden;

  &::after {
    content: " ";
    display: inline-block;
    position: absolute;
    background: radial-gradient(circle at left, #1772BD 0, #3045A1 1%, #0C141A 40%, #131313 60%);
    height: 100%;
    max-height: 1000px;
    width: 100vw;
    max-width: 1000px;
    top: 344px;
    left: -226px;
    z-index: -3;
  }

  img {
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3 {
    margin: unset;
  }
}





//pages

//other components
@import "./style/dark-background";
@import "./style/cookie-banner";
