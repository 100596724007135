.border-block-quote-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--text-color);

  .border-block-quote-details {
    display: flex;
    margin-bottom: 20px;

    .border-block-quote-image-wrapper{
      img {
        border-radius: 50%;
        height: 70px;
        object-fit: contain;
      }
    }

    .border-block-quote-name-wrapper{
      display: flex;
      flex-direction: column;
      margin: 0 0 0 20px;
      justify-content: center;

      p {
        margin: 0;
      }

      span {
        font-size: 15px;
        line-height: 14px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .border-block-quote-wrapper {
    .border-block-quote-details {
      margin: 0;

      p {
        font-size: var(--mobile-subtitle-font-size);
        line-height: 19px;
      }

      span {
        font-size: var(--mobile-body-font-size) !important;
        line-height: 14px;
      }
    }


    .border-block-text {
      line-height: 17px !important;
      font-size: var(--mobile-body-font-size) !important;
    }
  }
}
