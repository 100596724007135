.review-block-wrapper{
  display: flex;
  justify-content: space-between;

  .review-block-right {
    width: 50%;
    height: 100%;
  }

  .review-block-left {
    display: flex;
    flex-direction: column;
    width: 30%;

    .review-block-title {
      margin-top: 20px;
    }

    .review-block-content {
      p {
        font-weight: 300;
      }
    }

    Button {
      margin: 20px 0 0 0px;
    }
  }
}

@media screen and (max-width: 850px) {
  .review-block-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .review-block-right {
      width: 100%;
    }

    .review-block-left {
      margin: 0;
      width: 100%;

      .review-block-content p {
        line-height: 19px;
      }

      .review-block-left-title-img {
        display: none;
      }

      Button {
        display: none;
      }
    }
  }
}
