$quote-height: 325px;

.quote-carousel {
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: -20px;
    background: linear-gradient(to bottom, transparent, var(--bg-color));
  }

  .quote-carousel-button-wrapper {
    position: absolute;
    left: 0;
    top: calc($quote-height + 8.5px);
    transform: translate(-30%, -50%);
    z-index: 99;
  }

  .quote-carousel-wrapper {
    width: 100%;
    position: relative;
    height: 500px;
    overflow-y: hidden;

    .quote-carousel-items {
      position: absolute;
      left: 0;
      transition: all 2s ease-in-out;
      top: 0;

      .slide {
        display: block;
        margin: 0 0 17px 0;
        opacity: 0.5;
        transition: opacity 0.5s ease-in;

        .border-block-wrapper {
          height: 100%;
          min-height: $quote-height;
        }
      }

      .selected {
        bottom: 0;
        opacity: 1;
        display: block;
      }

      .pre-selected {
        bottom: 0;
        opacity: 0.5;
        display: block;
      }
    }

    .quote-carousel-navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      padding: 10px;
      border-radius: 100px;
      color: var(--text-color);

      &:hover {
        background-color: rgba(255, 255, 255, 0.106);
      }

      &.up {
        top: 0;
      }

      &.down {
        bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 850px) {
.quote-carousel {
  .quote-carousel-button-wrapper {
    left: 7.5px;
    }
  }
}
