.block {
  max-width: 350px;
  background-color: var(--bg-color-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 24px;

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      max-height: 150px;
      filter: var(--svg-filter-info-block);
    }
  }

  .block-text{
    text-align: center;
    height: 100%;
    margin-top: 20px;

    .info {
      color: var(--text-color-secondary);
      font-family: "proxima-nova", sans-serif;
      font-weight: 300;
      margin: 0;
    }
  }
}

@media screen and (max-width: 850px) {
  .block {
    padding: 10px;
    width: 100%;
  height: 100%;
    border-radius: 10%;

    .block-text {
      .title,
      .info {
        font-size: var(--mobile-body-font-size);
        line-height: 20px;
      }
    }
  }
}
